import distribution_img from '../../assets/images/img_distribution-graph01.png';
import distribution_img_m from '../../assets/images/img_distribution-graph01-m.png';
import distribution2_img from '../../assets/images/img_distribution-graph02.png';
import distribution2_img_m from '../../assets/images/img_distribution-graph02-m.png';

const SectionThree = () => {
	return (
		<section className="feature03">
			<h2 className="tit">
				<strong className="text-neon">Distribution</strong>
			</h2>
			<div className="distrib-wrap">
				<div className="cont01">
					<figure>
						<span className="pc">
							<img src={distribution_img} alt="" />
						</span>
						<span className="mobile">
							<img src={distribution_img_m} alt="" />
						</span>
					</figure>
					<div>
						<ul>
							<li>
								<strong>Name</strong>
								<span>Recharge</span>
							</li>
							<li>
								<strong>Ticker</strong>
								<span>RCG</span>
							</li>
							<li>
								<strong>Total Supply</strong>
								<span>1,000,000,000 RCG</span>
							</li>
						</ul>
						<div>
							<span className="pc">
								<img src={distribution2_img} alt="" />
							</span>
							<span className="mobile">
								<img src={distribution2_img_m} alt="" />
							</span>
						</div>
					</div>
				</div>
				<div className="cont02">
					<strong>Recharge Token address</strong>
					<ul>
						<li>
							<a
								href="https://etherscan.io/token/0xe74bE071f3b62f6A4aC23cA68E5E2A39797A3c30"
								target="_blank">
								<em>KCC (Kucoin Community Chain) :</em>{' '}
								0xe74bE071f3b62f6A4aC23cA68E5E2A39797A3c30
							</a>
						</li>
						<li>
							<a
								href="https://bscscan.com/token/0x2D94172436D869c1e3c094BeaD272508faB0d9E3"
								target="_blank">
								<em>BEP-20 (Binance Smart Chain) :</em>{' '}
								0x2D94172436D869c1e3c094BeaD272508faB0d9E3
							</a>
						</li>
						{/* <li>
              <a
                href="https://hecoinfo.com/token/0xbddC276CACC18E9177B2f5CFb3BFb6eef491799b"
                target="_blank"
              >
                <em>HRC-20 (Huobi Eco Chain) :</em>{" "}
                0xbddC276CACC18E9177B2f5CFb3BFb6eef491799b
              </a>
            </li>
            <li>
              <a
                href="https://solscan.io/token/3TM1bok2dpqR674ubX5FDQZtkyycnx1GegRcd13pQgko"
                target="_blank"
              >
                <em>SPL (Solana Network) :</em>{" "}
                3TM1bok2dpqR674ubX5FDQZtkyycnx1GegRcd13pQgko
              </a>
            </li> */}
					</ul>
				</div>
			</div>
		</section>
	);
};

export default SectionThree;
