import on1 from '../../assets/images/img_is-on01.png';
import on2 from '../../assets/images/img_is-on02.png';
import on3 from '../../assets/images/img_is-on03.png';
import on4 from '../../assets/images/img_is-on04.png';
import on5 from '../../assets/images/img_is-on05.png';
import on6 from '../../assets/images/img_is-on06.png';
import on7 from '../../assets/images/img_is-on07.png';
import on8 from '../../assets/images/btn_crologo.svg';

const SectionNine = () => {
	return (
		<section className="feature09">
			<h2 className="tit">
				<strong className="text-neon">Recharge is on</strong>
			</h2>
			<ul>
				{/*<a href=" https://v2.info.uniswap.org/pair/0x9c20be0f142fb34f10e33338026fb1dd9e308da3 " target="_blank">*/}
				{/*  <li>*/}
				{/*    <figure>*/}
				{/*      <img src={on1} alt="" />*/}
				{/*    </figure>*/}
				{/*    <p>Decentralized trading protocol</p>*/}
				{/*  </li>*/}
				{/*</a>*/}
				<a
					href="https://coinmarketcap.com/currencies/recharge/"
					target="_blank">
					<li>
						<figure>
							<img src={on2} alt="" />
						</figure>
						<p>Price-tracking website for crypto assets</p>
					</li>
				</a>
				<a href="https://www.coingecko.com/en/coins/recharge" target="_blank">
					<li>
						<figure>
							<img src={on3} alt="" />
						</figure>
						<p>
							provides a fundamental analysis of the digital currency market
						</p>
					</li>
				</a>
				<a href="https://xangle.io/project/RCG/key-info" target="_blank">
					<li>
						<figure>
							<img src={on5} alt="" />
						</figure>
						<p>Provides disclosure practices of the crypto industry</p>
					</li>
				</a>
				<a href="https://cobak.co.kr/community/146" target="_blank">
					<li>
						<figure>
							<img src={on6} alt="" />
						</figure>
						<p>No.1 Crypto community in Korea</p>
					</li>
				</a>
				<a href="https://snapshot.org/#/therecharge.eth" target="_blank">
					<li>
						<figure>
							<img src={on7} alt="" />
						</figure>
						<p>
							Provides a off-chain, gasless, multi-governance community polling
							dashboard
						</p>
					</li>
				</a>
				<a href="https://crypto.com/" target="_blank">
					<li>
						<figure>
							<img src={on8} alt="" />
						</figure>
						<p>The world's fastest growing crypto solution</p>
					</li>
				</a>
			</ul>
		</section>
	);
};

export default SectionNine;
