import roadmap from "../../assets/images/img_roadmap.png";
import roadmap_m from "../../assets/images/img_roadmap-m.png";
import bg_about from "../../assets/images/bg_about.png";
import bg_about_m from "../../assets/images/bg_about-m.png";

const SectionFive = () => {
  return (
    <section className="feature05" id="roadmap">
      <h2 className="tit">
        <strong className="text-neon">Key Milestone</strong>
      </h2>
      <figure>
        <span className="pc">
          <img src={roadmap} alt="" />
        </span>
        <span className="mobile">
          <img src={roadmap_m} alt="" />
        </span>
      </figure>
      <span className="bg">
        <span className="pc">
          <img src={bg_about} alt="" />
        </span>
        <span className="mobile">
          <img src={bg_about_m} alt="" />
        </span>
      </span>
    </section>
  );
};

export default SectionFive;
