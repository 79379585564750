import axios from "axios";

export interface MediumLists {
    title: string;
    date: string;
    description: string;
    guid: string;
    link: string;

}

export const getMediumLists = async () => {
    return await axios.get<any>('https://admin.dev.api.therecharge.io/medium?lang=en&pretty').then(resp => resp.data.data.feeds);
}