import logo_text from "../../assets/images/logo_text.png";
import github_img from "../../assets/images/ico_link01-github.png";
import medium_img from "../../assets/images/ico_link02-medium.png";
import naver_img from "../../assets/images/ico_link03-naver.png";
import twitter_img from "../../assets/images/ico_link04-twitter.png";
import tel_img from "../../assets/images/ico_link05-tel.png";
import bsc_img from "../../assets/images/ico_link07-bcs.png";
import solscan_img from "../../assets/images/ico_link09-solscan.png";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";
import { ReactComponent as FooterLogo } from "../../assets/images/certik-logotype-h-w.svg";
import kcc_img from "../../assets/images/ic_kcc.svg";

const LinkWrapper = styled.div`
  @media (max-width: 1280px) {
    margin-top: 50px;
  }
`;

const UiWrapper = styled.ul`
  //svg {
  //  width: 300px;
  //}
  @media (max-width: 1280px) {
    //display: none;
    transform: translate(-18px, 0%);
  }
`;

const Footer = () => {
  return (
    <footer>
      <div className="inner-box">
        <div className="cont01">
          <span className="logo">
            <img src={logo_text} alt="" />
          </span>
          <p>Decentralized Incentive Hub for Electric Power Based Ecosystem.</p>
          <p>Info@therecharge.io</p>
        </div>
        <div className="cont02">
          <div>
            <strong>Explore</strong>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <HashLink smooth to="# about">
                  About
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="#tokens" data-target="#token">
                  Recharge Token
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="#nft">
                  NFT
                </HashLink>
              </li>
            </ul>
          </div>
          <div>
            <strong>Docs</strong>
            <ul>
              <li>
                <a
                  href="https://the-recharge.gitbook.io/therecharge/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Whitepaper
                </a>
              </li>
              <li>
                <a
                  href="https://www.certik.com/projects/therecharge"
                  target="_blank"
                  rel="noopener"
                >
                  Token Audit
                </a>
              </li>
              <li>
                <a href="https://the-recharge.gitbook.io/therecharge/6.-disclaimers" target="_blank" rel="noopener">
                  Disclaimer
                </a>
              </li>
              <li>
                <a
                  href="https://s3.ap-southeast-1.amazonaws.com/static.recharge.io/The_Recharge_logo.zip"
                  target="_blank"
                  rel="noopener"
                >
                  CI Download
                </a>
              </li>
              <li>
                <a
                  href="/UserGuide.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  User Guide
                </a>
              </li>
            </ul>
          </div>
        </div>
        <LinkWrapper className="cont03">
          <ul className="footer-link">
            <li>
              <a href="https://github.com/therecharge" target="_blank">
                <img src={github_img} alt="" />
              </a>
            </li>
            <li>
              <a href="https://medium.com/therecharge" target="_blank">
                <img src={medium_img} alt="" />
              </a>
            </li>
            <li>
              <a href="https://blog.naver.com/therecharge" target="_blank">
                <img src={naver_img} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/TheRecharge_ad" target="_blank">
                <img src={twitter_img} alt="" />
              </a>
            </li>
            <li>
              <a href="https://t.me/therecharge_official" target="_blank">
                <img src={tel_img} alt="" />
              </a>
            </li>

            <li>
              <a
                href="https://scan.kcc.io/address/0xe74bE071f3b62f6A4aC23cA68E5E2A39797A3c30"
                target="_blank"
              >
                <img src={kcc_img} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://bscscan.com/token/0x2D94172436D869c1e3c094BeaD272508faB0d9E3"
                target="_blank"
              >
                <img src={bsc_img} alt="" />
              </a>
            </li>

            <li>
              <a
                href="https://solscan.io/token/3TM1bok2dpqR674ubX5FDQZtkyycnx1GegRcd13pQgko"
                target="_blank"
              >
                <img src={solscan_img} alt="" />
              </a>
            </li>
          </ul>
          <UiWrapper style={{ marginTop: "20px" }}>
            <span
              style={{ fontSize: "20px", marginLeft: "20px", color: "#fff" }}
            >
              Audited by
            </span>
            <a
              href="https://www.certik.com/projects/therecharge"
              target="_blank"
              style={{ cursor: "pointer" }}
            >
              <FooterLogo style={{ position: "relative", bottom: "28px" }} />
            </a>
          </UiWrapper>
        </LinkWrapper>
      </div>
      <p className="copy">© 2022 Recharge Labs Ltd.</p>
    </footer>
  );
};

export default Footer;
