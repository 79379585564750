import mojito_img from '../../assets/images/mojito.png';
import bimart_img from '../../assets/images/img-bitmart.png';
import exchanges2_img from '../../assets/images/img_exchanges02.png';
import digifinex_img from '../../assets/images/digifinex.png';
import mexc_img from '../../assets/images/mexc.png';
import img_kuswap from '../../assets/images/img_kuswap.png';
import styled from 'styled-components';

const LiWrapper = styled.li`
	@media (max-width: 1280px) {
		margin-top: 20px !important;
	}
`;

const UlWrapper = styled.ul`
	margin-bottom: 70px;
	@media (max-width: 1280px) {
		margin-bottom: 20px !important;
	}
`;

const SectionSix = () => {
	return (
		<section className="feature06">
			<h2 className="tit">
				<strong className="text-neon">Exchanges</strong>
			</h2>
			<UlWrapper>
				{/*<a*/}
				{/*  href="https://app.mojitoswap.finance/info/token/0xe74be071f3b62f6a4ac23ca68e5e2a39797a3c30"*/}
				{/*  target="_blank"*/}
				{/*>*/}
				{/*  <li>*/}
				{/*    <img src={mojito_img} alt="" />*/}
				{/*  </li>*/}
				{/*</a>*/}

				{/* <a
					href="https://www.bitmart.com/trade/en?symbol=RCG_USDT&layout=basic"
					target="_blank">
					<li>
						<img src={bimart_img} alt="" />
					</li>
				</a>

				<a
					href="https://www.digifinex.com/en-ww/trade/USDT/RCG"
					target="_blank">
					<LiWrapper>
						<img src={digifinex_img} alt="" />
					</LiWrapper>
				</a> */}

				<a href="https://www.mexc.com/" target="_blank">
					<LiWrapper>
						<img src={mexc_img} alt="" />
					</LiWrapper>
				</a>

				{/* <a
					href="https://kuswap.info/#/pair/0xf531ee5cb4fd655522122d7036389474b7cec677"
					target="_blank">
					<LiWrapper>
						<img src={img_kuswap} alt="" />
					</LiWrapper>
				</a> */}
			</UlWrapper>
		</section>
	);
};

export default SectionSix;
