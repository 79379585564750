import React, { useEffect } from 'react';
import './App.css';
import { RecoilRoot } from 'recoil';
import i18n from './i18n';
import Header from './components/Header';
import { PublicRouter } from './common/router';
import Footer from './components/Footer';

function App() {
	useEffect(() => {
		i18n.changeLanguage('en'); // 초기 언어 설정
	}, []);

	return (
		<RecoilRoot>
			<main>
				<div id="contents">
					<Header />
					<PublicRouter />
					<Footer />
				</div>
			</main>
		</RecoilRoot>
	);
}

export default App;
