// images
import close_icon from '../../assets/images/btn_menu-close.png';
// @ts-ignore
import pdf from '../../static/whitepaper.pdf';
import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import LanguageSelector from '../LanguageSelector';
const MobileNavContainer = styled.div<{ click: boolean }>`
	.mobile-nav {
		left: ${(props) => (props.click ? '0px' : '-100%')};
		transition: all 0.25s;
	}
`;
const Header = () => {
	const [isVisible, setIsVisible] = useState(false);
	const [isClick, setIsClick] = useState(false);
	const [docsIsClick, setDocsIsClick] = useState(false);

	const onRemovePopup = () => {
		const content = document.querySelector('#contents')!;
		const html = document.querySelector('html')!;
		content.classList.remove('dim');
		html.classList.remove('nav-open');
		setIsClick(false);
		setDocsIsClick(false);
	};

	return (
		<>
			<header>
				<section className="header-inner">
					<h1>
						<a href="/">Recharge logo</a>
					</h1>
					<nav style={{ position: 'relative' }}>
						<LanguageSelector />
						<ul className="gnb">
							<li>
								<a href="#">Home</a>
							</li>
							<li>
								<HashLink smooth to="#about" data-target="#about">
									About
								</HashLink>
							</li>

							<li>
								<HashLink smooth to="#roadmap" data-target="#roadmap">
									Roadmap
								</HashLink>
							</li>
							<li>
								<HashLink smooth to="#tokens" data-target="#token">
									Recharge Token
								</HashLink>
							</li>
							<li>
								<HashLink smooth to="#nft" data-target="#nft">
									NFT
								</HashLink>
							</li>
							<li>
								<HashLink smooth to="#advisors" data-target="#advisors">
									Advisors
								</HashLink>
							</li>
							<li
								className="depth-link"
								onMouseOver={() => {
									setIsVisible(true);
								}}
								onMouseLeave={() => {
									setIsVisible(false);
								}}>
								<a href="#">Docs</a>
								<ul
									className="depth"
									style={{
										display: isVisible ? 'block' : 'none',
										width: '100px',
									}}>
									<li>
										<a
											href="https://the-recharge.gitbook.io/therecharge/"
											rel="noopener noreferrer"
											target="_blank">
											Whitepaper
										</a>
									</li>
									<li>
										<a
											href="https://www.certik.com/projects/therecharge"
											target="_blank"
											rel="noopener">
											Token Audit
										</a>
									</li>
									<li>
										<a
											href="https://the-recharge.gitbook.io/therecharge/6.-disclaimers"
											rel="noopener noreferrer"
											target="_blank">
											Disclaimer
										</a>
									</li>
									<li>
										<a
											href="https://s3.ap-southeast-1.amazonaws.com/static.recharge.io/The_Recharge_logo.zip"
											target="_blank">
											CI Download
										</a>
									</li>
									<li>
										<a
											href="/UserGuide.pdf"
											target="_blank"
											rel="noopener noreferrer">
											User Guide
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</nav>
					<a
						href="https://defi.therecharge.io"
						target="_blank"
						className="btn-neon">
						Launch App
					</a>
				</section>
			</header>
			<button
				className="btn-menu"
				onClick={() => {
					const content = document.querySelector('#contents')!;
					const html = document.querySelector('html')!;

					console.log(content, html);

					content.setAttribute('class', 'dim');
					html.setAttribute('class', 'nav-open');
					setIsClick(!isClick);
				}}>
				button
			</button>
			<MobileNavContainer click={isClick}>
				<div className="mobile-nav">
					<a href="#" className="close" onClick={onRemovePopup}>
						<img src={close_icon} alt="" />
					</a>
					<div className="gnb-list">
						<ul className="gnb">
							<ul className="gnb">
								<li>
									<HashLink smooth to="/">
										Home
									</HashLink>
								</li>
								<li>
									<HashLink
										smooth
										to="#about"
										data-target="#about"
										onClick={() => {
											onRemovePopup();
										}}>
										About
									</HashLink>
								</li>

								<li>
									<HashLink
										smooth
										to="#roadmap"
										data-target="#roadmap"
										onClick={() => {
											onRemovePopup();
										}}>
										Roadmap
									</HashLink>
								</li>
								<li>
									<a href="#token" data-target="#token">
										Recharge Token
									</a>
								</li>
								<li>
									<HashLink
										smooth
										to="#nft"
										data-target="#nft"
										onClick={() => {
											onRemovePopup();
										}}>
										NFT
									</HashLink>
								</li>
								<li>
									<HashLink
										smooth
										to="#advisors"
										data-target="#advisors"
										onClick={() => {
											onRemovePopup();
										}}>
										Advisors
									</HashLink>
								</li>
								<li className="depth-link">
									<a
										href="#"
										onClick={() => {
											setDocsIsClick(!docsIsClick);
										}}>
										Docs
									</a>
									<ul
										className="depth"
										style={{ display: docsIsClick ? 'block' : 'none' }}>
										<li>
											<a
												href="https://the-recharge.gitbook.io/therecharge/"
												target="_blank"
												rel="noopener noreferrer">
												Whitepaper
											</a>
										</li>
										<li>
											<a
												href="https://www.certik.com/projects/therecharge"
												target="_blank"
												rel="noopener">
												Token Audit
											</a>
										</li>
										<li>
											<a href="https://the-recharge.gitbook.io/therecharge/6.-disclaimers" target="_blank" rel="noopener">
												Disclaimer
											</a>
										</li>
										<li>
											<a
												href="https://s3.ap-southeast-1.amazonaws.com/static.recharge.io/The_Recharge_logo.zip"
												target="_blank"
												rel="noopener">
												CI Download
											</a>
										</li>
										<li>
											<a
												href="/UserGuide.pdf"
												target="_blank"
												rel="noopener noreferrer">
												User Guide
											</a>
										</li>
									</ul>
								</li>
								{isClick && <LanguageSelector />}
							</ul>
						</ul>
						<a
							href="https://defi.therecharge.io/"
							target="_blank"
							className="btn-neon">
							Launch App
						</a>
					</div>
				</div>
			</MobileNavContainer>
		</>
	);
};

export default Header;
