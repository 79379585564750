import Slider from 'react-slick';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
// import "react-multi-carousel/lib/styles.css";
import moment from 'moment';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import left_img from '../../assets/images/btn_slider-prev.png';
import right_img from '../../assets/images/btn_slider-next.png';
import { getMediumLists, MediumLists } from '../../api';
import { useEffect, useState } from 'react';

const SectionWrapper = styled.section`
	@media (max-width: 1280px) {
		padding: 80px 34px 128px;
		max-width: 365px;
		width: 365px;
	}
`;

const SlideWrapper = styled.div`
	li {
		list-style: none;
	}
	ul {
		padding: 0;
	}
	.slick-list {
		width: 1120px;
	}
	figure {
		width: 348px;
		height: 240px;
		border-radius: 32px;
		box-shadow: 0 37.1px 59.1px 0 rgba(0, 0, 0, 0.05);
		-webkit-box-shadow: 0 37.1px 59.1px 0 rgba(0, 0, 0, 0.05);
		-ms-box-shadow: 0 37.1px 59.1px 0 rgba(0, 0, 0, 0.05);
		background: #dadada;
		margin: 0;
		> img {
			object-fit: cover;
		}
	}
	.slick-dots {
		display: list-item !important;
		justify-content: center;
		left: 38%;
		li.slick-active button::before {
			color: rgb(132, 84, 235);
		}
	}

	//.slick-slide {
	//  margin-left: 10px; !important;
	//}

	.slick-dots {
		display: flex;
		justify-content: center;
		position: relative;
		top: 50px;
		li button::before {
			opacity: 0.2;
			color: #fff;
		}
	}

	@media (max-width: 1280px) {
		.slick-slider {
			margin-top: 40px;
		}
		ul {
			//padding-left: 20px;
		}
		figure {
			width: 307px;
			height: 226px;
		}
		li {
			margin-left: 0;
		}
		li span {
			padding-left: 16px;
		}

		li strong {
			font-size: 14px;
			line-height: 2;
		}
		.slick-dots {
			position: relative;
			left: 26px;
		}
	}
`;

const PreviousArrowImage = styled.img`
	width: 48px;
	position: relative;
	top: 155px;
	left: -7%;
	cursor: pointer;

	@media (max-width: 1280px) {
		display: none;
	}
`;

const NextArrowImage = styled.img`
	position: absolute;
	right: -56px;
	width: 48px;
	bottom: 219px;
	cursor: pointer;

	@media (max-width: 1280px) {
		display: none;
	}
`;

const SectionEight = () => {
	const [lists, setLists] = useState<any>([]);
	// const responsive = {
	//   desktop: {
	//     breakpoint: { max: 3000, min: 1300 },
	//     items: 1,
	//   },
	//   tablet: {
	//     breakpoint: { max: 1300, min: 464 },
	//     items: 3,
	//     slidesToSlide: 2, // optional, default to 1.
	//   },
	//   mobile: {
	//     breakpoint: { max: 464, min: 0 },
	//     items: 3,
	//     slidesToSlide: 3, // optional, default to 1.
	//   },
	// };

	function LeftNavButton(props: any) {
		const { className, style, onClick } = props;
		return (
			<div
				className="slick-arrow"
				style={{ ...style, display: 'block' }}
				onClick={onClick}>
				<PreviousArrowImage src={left_img} alt="arrow_left" />
			</div>
		);
	}

	function RightNavButton(props: any) {
		const { className, style, onClick } = props;
		return (
			<div
				className="slick-arrow"
				style={{ ...style, display: 'block' }}
				onClick={onClick}>
				<NextArrowImage src={right_img} alt="arrow_left" />
			</div>
		);
	}

	const settings: any = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: <LeftNavButton />,
		nextArrow: <RightNavButton />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	const bootstrap = async () => {
		await getMediumLists().then(setLists);
	};

	useEffect(() => {
		bootstrap();
	}, []);

	return (
		<SectionWrapper className="feature08">
			<h2 className="tit">
				<strong className="text-neon">Medium</strong>
			</h2>

			<SlideWrapper>
				{/* @ts-ignore */}
				<Slider className="slider-wrap slider-w" {...settings}>
					{lists.map((item: any, i: number) => (
						<a
							href={item.link}
							target="_blank"
							key={i}
							style={{ cursor: 'pointer' }}>
							<ul>
								<li>
									<figure>
										<img
											style={{
												width: '100%',
												height: '100%',
												borderRadius: '30px',
											}}
											src={item.thmbnail}
										/>
									</figure>
									<strong>{item.title}</strong>
									<span>{moment(item.date).format('MMM DD, YYYY')}</span>
								</li>
							</ul>
						</a>
					))}
				</Slider>
			</SlideWrapper>
		</SectionWrapper>
		// <div className="slider-wrap slider-m">
		//   <div>
		//     <ul className="slider">
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//       <li>
		//         <figure></figure>
		//         <strong>Staking Pool Info for November</strong>
		//         <span>Nov 6th, 2021</span>
		//       </li>
		//     </ul>
		//   </div>
		// </div>
		// </section>
	);
};

export default SectionEight;
