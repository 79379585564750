import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translation/en';
import ko from './translation/ko';

i18n
	.use(initReactI18next)
	.init({
		resources: {
			en,
			ko,
		},
		lng: 'ko',
		fallbackLng: 'en',
		nsSeparator: ':',
		interpolation: {
			escapeValue: true,
		},
	})
	.then(() => {
		console.log('i18n init success');
	});

export default i18n;
