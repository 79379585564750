import { useEffect, useState } from 'react';

import classNames from 'classnames';

//images
import about1 from '../../assets/images/img_about01.png';
import about1_m from '../../assets/images/img_about01-m.png';
import about2 from '../../assets/images/img_about02.png';
import about2_m from '../../assets/images/img_about02-m.png';
import about3 from '../../assets/images/img_about03.png';
import about3_m from '../../assets/images/img_about03-m.png';
import about4 from '../../assets/images/img_about04.png';
import about4_m from '../../assets/images/img_about04-m.png';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import styled from 'styled-components';

const SectionOneBoldPtag = styled.p`
	font-weight: bold;
	fonst-size: 17px;
`;

const SectionOne = () => {
	const { t } = useTranslation();
	const worksTabs = [
		t('howitworks:workTabs:chargingStation:title'),
		t('howitworks:workTabs:rechargeSwap:title'),
		t('howitworks:workTabs:ecosystem:title'),
		t('howitworks:workTabs:rechargeVirtuousCycle:title'),
	];
	const [currentTabs, setCurrentTabs] = useState(
		t('howitworks:workTabs:chargingStation:title')
	);
	useEffect(() => {
		// if (i18n.language === 'en') {
		// 	switch (currentTabs) {
		// 		case 'Charging Station':
		// 			setCurrentTabs(t('howitworks:workTabs:chargingStation:title'));
		// 			break;
		// 		case 'Recharge Swap':
		// 			setCurrentTabs(t('howitworks:workTabs:rechargeSwap:title'));
		// 			break;
		// 		case 'Ecosystem':
		// 			setCurrentTabs(t('howitworks:workTabs:ecosystem:title'));
		// 			break;
		// 		case 'Recharge Virtuous Cycle':
		// 			setCurrentTabs(t('howitworks:workTabs:rechargeVirtuousCycle:title'));
		// 			break;
		// 		default:
		// 			break;
		// 	}
		// } else if (i18n.language === 'ko') {
		// 	switch (currentTabs) {
		// 		case '차징 스테이션':
		// 			setCurrentTabs(t('howitworks:workTabs:chargingStation:title'));
		// 			break;
		// 		case '리차지 스왑':
		// 			setCurrentTabs(t('howitworks:workTabs:rechargeSwap:title'));
		// 			break;
		// 		case '에코시스템':
		// 			setCurrentTabs(t('howitworks:workTabs:ecosystem:title'));
		// 			break;
		// 		case '리차지 선순환 사이클':
		// 			setCurrentTabs(t('howitworks:workTabs:rechargeVirtuousCycle:title'));
		// 			break;
		// 		default:
		// 			break;
		// 	}
		// } 버그가 있어서 임시로 주석처리 정보 유지 안됌
		setCurrentTabs(t('howitworks:workTabs:chargingStation:title'));
		// 언어 번역시 초기셋팅 차징 스테이션으로
	}, [i18n.language]);
	return (
		<section className="feature01" id="about">
			<h2 className="tit">
				About<strong className="text-neon">{t('howitworks:title')}</strong>
			</h2>
			<ul className="tab">
				{worksTabs.map((tab, i) => (
					<li
						key={i}
						className={currentTabs === tab ? 'curr' : 'none'}
						style={{ cursor: 'pointer' }}
						onClick={() => {
							setCurrentTabs(tab);
						}}>
						<a>{tab}</a>
					</li>
				))}
			</ul>
			<div className="cont-box">
				<div
					className={classNames(
						'cont',
						currentTabs === t('howitworks:workTabs:chargingStation:title')
							? 'on'
							: 'none'
					)}>
					<SectionOneBoldPtag>
						{i18n.language === 'ko' && t('howitworks:workTabs:bold')}
					</SectionOneBoldPtag>
					<p>{t('howitworks:workTabs:chargingStation:description')}</p>
					<figure>
						<span className="pc">
							<img src={about1} alt="" />
						</span>
						<span className="mobile">
							<img src={about1_m} alt="" />
						</span>
					</figure>
				</div>
				<div
					className={classNames(
						'cont',
						currentTabs === t('howitworks:workTabs:rechargeSwap:title')
							? 'on'
							: 'none'
					)}>
					<SectionOneBoldPtag>
						{i18n.language === 'ko' && t('howitworks:workTabs:bold')}
					</SectionOneBoldPtag>
					<p>{t('howitworks:workTabs:rechargeSwap:description')}</p>
					<figure>
						<span className="pc">
							<img src={about2} alt="" />
						</span>
						<span className="mobile">
							<img src={about2_m} alt="" />
						</span>
					</figure>
				</div>
				<div
					className={classNames(
						'cont',
						currentTabs === t('howitworks:workTabs:ecosystem:title')
							? 'on'
							: 'none'
					)}>
					<SectionOneBoldPtag>
						{i18n.language === 'ko' && t('howitworks:workTabs:bold')}
					</SectionOneBoldPtag>
					<p>{t('howitworks:workTabs:ecosystem:description')}</p>
					<figure>
						<span className="pc">
							<img src={about3} alt="" />
						</span>
						<span className="mobile">
							<img src={about3_m} alt="" />
						</span>
					</figure>
				</div>
				<div
					className={classNames(
						'cont',
						currentTabs === t('howitworks:workTabs:rechargeVirtuousCycle:title')
							? 'on'
							: 'none'
					)}>
					<SectionOneBoldPtag>
						{i18n.language === 'ko' && t('howitworks:workTabs:bold')}
					</SectionOneBoldPtag>
					<p>{t('howitworks:workTabs:rechargeVirtuousCycle:description')}</p>
					<figure>
						<span className="pc">
							<img src={about4} alt="" />
						</span>
						<span className="mobile">
							<img src={about4_m} alt="" />
						</span>
					</figure>
				</div>
			</div>
		</section>
	);
};

export default SectionOne;
