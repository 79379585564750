import nft_img from '../../assets/images/img_nft.png';
import { useTranslation } from 'react-i18next';

const SectionFour = () => {
	const { t } = useTranslation();
	return (
		<section className="feature04" id="nft">
			<h2 className="tit">
				<strong className="text-neon">NFT</strong>
			</h2>
			<div className="flex">
				<figure>
					<img src={nft_img} alt="" />
				</figure>
				<div>
					<strong>{t('nft:title')}</strong>
					<p>{t('nft:description')}</p>
					<a
						href="https://nft.therecharge.io/"
						className="btn-neon"
						target="_blank">
						Go to The Recharge NFT
					</a>
				</div>
			</div>
		</section>
	);
};

export default SectionFour;
