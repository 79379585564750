import home from './home.json';
import howitworks from './howitworks.json';
import features from './features.json';
import nft from './nft.json';

export default {
	home,
	howitworks,
	features,
	nft,
};
