import features from '../../assets/images/img_features01.png';
import features02 from '../../assets/images/img_features02.png';
import features03 from '../../assets/images/img_features03.png';
import features04 from '../../assets/images/img_features04.png';
import { useTranslation } from 'react-i18next';

const SectionTwo = () => {
	const { t } = useTranslation();
	return (
		<section className="feature02" id="tokens">
			<h2 className="tit">
				Recharge Token<strong className="text-neon">Features</strong>
			</h2>
			<ul>
				<li>
					<figure>
						<img src={features} alt="" />
					</figure>
					<div>
						<strong>{t('features:frequentUsePoint:title')}</strong>
						<p>{t('features:frequentUsePoint.description')}</p>
					</div>
				</li>
				<li>
					<figure>
						<img src={features02} alt="" />
					</figure>
					<div>
						<strong>{t('features:automatedCarbonRedemption:title')}</strong>
						<p>{t('features:automatedCarbonRedemption:description')}</p>
					</div>
				</li>
				<li>
					<figure>
						<img src={features03} alt="" />
					</figure>
					<div>
						<strong>{t('features:rechargeGovernance:title')}</strong>
						<p>
							<i className="br">
								{t('features:rechargeGovernance:description')}
							</i>
						</p>
					</div>
				</li>
				<li>
					<figure>
						<img src={features04} alt="" />
					</figure>
					<div>
						<strong>{t('features:crossChainCompatibility:title')}</strong>
						<p>
							<i className="br">
								{t('features:crossChainCompatibility:description')}
							</i>{' '}
						</p>
					</div>
				</li>
			</ul>
		</section>
	);
};

export default SectionTwo;
