import { Route, Switch } from "react-router-dom";

import { routes } from "./constants";
import HomePage from "../pages/Home";

export const PublicRouter = () => {
  const { HOME } = routes;

  return (
    <>
      <Switch>
        <Route exact path={HOME} component={HomePage} />
      </Switch>
    </>
  );
};
