import kv_img from '../../assets/images/img_kv.png';

import kv_m_img from '../../assets/images/img_kv-m.png';
import SectionOne from '../../components/HomeSection/SectionOne';
import SectionTwo from '../../components/HomeSection/SectionTwo';
import SectionThree from '../../components/HomeSection/SectionThree';
import SectionFour from '../../components/HomeSection/SectionFour';
import SectionFive from '../../components/HomeSection/SectionFive';
import SectionSix from '../../components/HomeSection/SectionSix';
import SectionSeven from '../../components/HomeSection/SectionSeven';
import SectionEight from '../../components/HomeSection/SectionEight';
import SectionNine from '../../components/HomeSection/SectionNine';
import { useTranslation } from 'react-i18next';
import SectionTen from '../../components/HomeSection/SectionTen';
// import desktop_main_video from "./video/recharge_sample_001.mp4";

import { useRef } from 'react';
import styled from 'styled-components';

const BoldPtag = styled.p`
	font-weight: bold !important;
	font-size: 17px !important;
`;

// function importAll(r: any) {
//   let images: any = {};
//   r.keys().map((item: any) => {
//     images[item.replace("./", "")] = r(item);
//   });
//   return images;
// }
//
// const images = importAll(
//   // @ts-ignore
//   require.context("../../assets/mutiple_images", false, /.png/)
// );
//
// const convertNums = (num: number) => {
//   if (num < 10) {
//     return `0000${num}`;
//   } else if (num < 100) {
//     return `000${num}`;
//   } else if (num < 1000) {
//     return `00${num}`;
//   } else {
//     return `0${num}`;
//   }
// };
//
// const result: string[] = [];
// for (let i = 0; i < 1000; i++) {
//   const nums = convertNums(i + 1);
//   const path = `TheRecharge_Neoncar${nums}.png`;
//   result.push(images[path].default);
// }

const HomePage = () => {
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const { t } = useTranslation();

	return (
		<article>
			<section className="kv">
				<div className="text">
					<strong className="text-neon">The Recharge</strong>
					<BoldPtag>{t('home:bold')}</BoldPtag>
					<p>{t('home:description')}</p>
					<a
						href="https://defi.therecharge.io/"
						target="_blank"
						className="btn-neon">
						Launch App
					</a>
				</div>
				<figure className="pc">
					{/*<span className="pc">*/}
					{/*  <img src={kv_img} alt="" />*/}
					{/*</span>*/}
					{/*<span className="mobile">*/}
					{/*  <img src={kv_m_img} alt="" />*/}
					{/*</span>*/}
					<video
						autoPlay
						muted
						loop
						// preload="auto"
						// width="100%"
						// height="100%"
						// controls
						ref={videoRef}
						// controlsList="nodownload"
					>
						<source
							src="https://s3.ap-northeast-2.amazonaws.com/therecharge.io/recharge_sample_001.mp4"
							type="video/mp4"
						/>

						<source
							src="https://s3.ap-northeast-2.amazonaws.com/therecharge.io/recharge_sample_001.webm"
							type="video/webm"
						/>
					</video>
				</figure>

				<figure className="mobile">
					<video autoPlay loop muted>
						<source
							src="https://s3.ap-northeast-2.amazonaws.com/therecharge.io/recharge_sample_mobile_001.mp4"
							type="video/mp4"
						/>
						<source
							src="https://s3.ap-northeast-2.amazonaws.com/therecharge.io/recharge_sample_mobile_001.webm"
							type="video/webm"
						/>
					</video>
				</figure>
			</section>
			<SectionOne />
			<SectionTwo />
			<SectionThree />
			<SectionFour />
			<SectionFive />
			<SectionSix />
			<SectionSeven />
			<SectionEight />
			<SectionNine />
			<div
				id="crypto-widget-CoinList"
				data-theme="dark"
				data-design="modern"
				data-coin-ids="6186"
			/>
			{/*<SectionTen />*/}
		</article>
	);
};

export default HomePage;
