import support01 from "../../assets/images/img_support01.png";
import support02 from "../../assets/images/img_support02.png";
import support03 from "../../assets/images/img_support03.png";
import support04 from "../../assets/images/img_support04.png";
import support05 from "../../assets/images/kcc.png";

const SectionSeven = () => {
  return (
    <section className="feature07">
      <h2 className="tit">
        <strong className="text-neon">Supporting Platforms</strong>
      </h2>
      <ul>
        <a href="https://ethereum.org/en/" target="_blank">
          <li>
            <img src={support01} alt="" />
          </li>
        </a>

        <a href="https://www.binance.org/en/smartChain" target="_blank">
          <li>
            <img src={support02} alt="" />
          </li>
        </a>

        <a href="https://www.kcc.io" target="_blank">
          <li>
            <img src={support05} alt="" />
          </li>
        </a>

        <a href="https://solana.com/ko" target="_blank">
          <li>
            <img src={support03} alt="" />
          </li>
        </a>

        <a href="https://www.hecochain.com/en-us/" target="_blank">
          <li>
            <img src={support04} alt="" />
          </li>
        </a>
      </ul>
    </section>
  );
};

export default SectionSeven;
