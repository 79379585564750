import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import World from '../../assets/images/world.png';

interface stylesType {
	container: React.CSSProperties;
	image: React.CSSProperties;
	button: React.CSSProperties;
}

function LanguageSelector() {
	const { i18n } = useTranslation();

	const handleChangeLanguage = (language: string) => {
		i18n.changeLanguage(language);
	};
	const currentLanguage = i18n.language;

	const styles: stylesType = {
		container: {
			position: 'fixed',
			width: '150px',
			top: '40px',
			right: '130px',
			zIndex: '9999',
			textAlign: 'center',
		},
		image: {
			width: '25px',
			height: '25px',
			filter: 'invert(1)',
		},
		button: {
			border: 'none',
			background: 'none',
			cursor: 'pointer',
			color: '#fff',
			padding: '0 10px',
			fontSize: '20px',
			fontWeight: 'bold',
		},
	};

	return (
		<div style={styles.container}>
			<img src={World} alt="world" style={styles.image} />
			<button
				style={{
					...styles.button,
					color: currentLanguage === 'en' ? '#fff' : 'gray',
				}}
				onClick={() => handleChangeLanguage('en')}>
				en
			</button>
			<button
				style={{
					...styles.button,
					borderLeft: '1px solid #fefefe',
					color: currentLanguage === 'ko' ? '#fff' : 'gray',
				}}
				onClick={() => handleChangeLanguage('ko')}>
				ko
			</button>
		</div>
	);
}

export default LanguageSelector;
